export default {
  methods: {
    scrollTo(ref, shift = -20, delay = 50) {
      const el = this.$refs[ref];
      if (!el) throw `Ubable to scroll: no ref for '${ref}'`;
      // const top = element.offsetTop;
      const top = el.getBoundingClientRect().top + window.scrollY;

      // console.log(`scroll to ${ref} at ${top}`);
      setTimeout(() => {
        window.scrollTo(0, top + shift);
      }, delay);
    },
    confetti() {
      window.jsConfetti.addConfetti({
        // emojis: ["🌈", "⚡️", "💥", "✨", "💫", "🌸"],
        // emojis: ["🐱", "😻", "😽", "😽"],
      });
    },
  },
};
