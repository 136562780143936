import { createApp } from "vue";
import App from "./App.vue";
export const app = createApp(App);

// AOS
// https://github.com/michalsnik/aos/tree/v2
import AOS from "aos";
import "aos/dist/aos.css";
app.AOS = new AOS.init({
  // disable: "phone",
  offset: 200,
  duration: 600,
  easing: "ease-in-out-sine",
  delay: 100,
});
app.use(AOS);

// router
import router from "./router";
app.use(router);

// axios
import axios from "axios";
import VueAxios from "vue-axios";
app.use(VueAxios, axios);

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

// styles
import "@/styles/index.scss";

// global mixins
import mixins from "@/mixins";
app.mixin(mixins);

// confetti :)
import JSConfetti from "js-confetti";
window.jsConfetti = new JSConfetti();

// mount
app.mount("#app");
