<template>
  <div
    class="navbar"
    :class="{
      top: scrollPosition < 50,
    }"
  >
    <div class="container large">
      <div class="logo">
        <router-link :to="{ hash: '#top' }" @click="showMenu = false">
          marsimonio.it
        </router-link>
      </div>
      <div
        class="hamburger"
        @click="showMenu = !showMenu"
        :class="{ open: showMenu }"
      >
        <div class="icon"></div>
      </div>
      <div class="menu" :class="{ open: showMenu }" @click="showMenu = false">
        <div class="links">
          <div class="marghe">
            <img src="@/assets/marghe.png" />
          </div>
          <router-link :to="{ hash: '#info' }" @click="showMenu = false">
            <span>DOVE</span>
          </router-link>
          <router-link :to="{ hash: '#viaggio' }" @click="showMenu = false">
            <span>VIAGGIO</span>
          </router-link>
          <router-link :to="{ hash: '#regalo' }" @click="showMenu = false">
            <span>REGALO</span>
          </router-link>
          <router-link :to="{ hash: '#rsvp' }" @click="showMenu = false">
            <span>RSVP</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      scrollPosition: null,
      showMenu: false,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  components: {},
};
</script>

<style lang="scss" scoped>
$hamburger-height: 4px;
$hamburger-gap: 4px;
$hamburger-width: 30px;
$hamburger-size: $hamburger-height + $hamburger-gap;
$navbar-height: 58px;
.hamburger {
  position: relative;
  width: $hamburger-width;
  height: calc(#{$hamburger-height} * 3 + #{$hamburger-gap} * 2);
  .icon {
    cursor: pointer;
    transition-duration: 0.5s;
    position: absolute;
    height: $hamburger-height;
    width: 100%;
    top: $hamburger-size;
    background-color: $dark;

    &::before {
      transition-duration: 0.5s;
      position: absolute;
      width: 100%;
      height: $hamburger-height;
      left: 0;
      background-color: $dark;
      content: "";
      top: -$hamburger-size;
    }
    &::after {
      transition-duration: 0.5s;
      position: absolute;
      width: 100%;
      height: $hamburger-height;
      left: 0;
      background-color: $dark;
      content: "";
      top: $hamburger-size;
    }
  }
  &.open {
    .icon {
      background: transparent;
      &::before {
        transform: translateY($hamburger-size) rotate(45deg);
      }
      &::after {
        transform: translateY(-$hamburger-size) rotate(-45deg);
      }
    }
  }
}

.menu {
  text-transform: uppercase;
  font-size: 1em;
  font-family: $font-title;
  z-index: 1;
}

.logo {
  z-index: 2;
}
.hamburger {
  z-index: 2;
}

// hamburger menu
@media screen and (max-width: 768px) {
  .menu {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: -110%;
    height: 100%;
    background: #ffffffcc;
    transition: bottom 0.5s ease;

    .links {
      bottom: 50%;
      transform: translateY(60%); // leave some space for marghe

      position: absolute;
      width: 100%;

      .marghe {
        transform: translateY(-100%);
        position: absolute;
        top: 0;
        z-index: 20;
        left: 0;
        right: 0;

        img {
          width: 150px;
          transform: translate(-10px, 10px);
        }
      }
      a {
        width: calc(100% + 20px); // a little bit for the rotation
        transform: translateX(-10px) rotate(-3deg);
        display: block;
        background: $white;
        padding: 0.3em;
        border-top: 5px solid $cyan;
        font-size: 2em;
        &:last-child {
          border-bottom: 5px solid $cyan;
        }
      }
    }
    &.open {
      bottom: 0;
    }
  }
}

// normal menu
@media screen and (min-width: 769px) {
  .hamburger {
    display: none;
  }
  .menu {
    .links {
      .marghe {
        display: none;
      }
      a {
        display: inline-block;
        padding: 0 0.5em;
        border-right: 3px solid $dark;
        line-height: 1.2em;
        &:last-child {
          border-right: 0;
          padding-right: 0;
        }
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

// 8 10 8 10 8
.navbar {
  background-color: white;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);

  box-sizing: border-box;
  padding: 0 2rem;

  z-index: 10;

  height: $navbar-height;

  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  transition: bottom 1s ease;

  .container {
    padding: 0 !important;
  }

  .logo {
    // text-transform: uppercase;
    font-family: $font-cursive;
    font-size: 1.5em;
  }
}
a {
  color: $dark;
  text-decoration: none;
  border-bottom: none;
  transition: color 0.5s ease;
}
a:hover {
  color: $cyan;
  span {
    display: inline-block;
    transform: rotate(3deg);
  }
}
</style>
