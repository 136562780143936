import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/Home.vue"),
  },
  // {
  //   path: "/",
  //   name: "wip",
  //   component: () => import("@/views/wip/WorkInProgress.vue"),
  // },
  {
    path: "/:catchAll(.*)",
    redirect: { path: "/" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      // const NAVBAR_GAP = 0;
      // let element = document.querySelector(to.hash);
      // if (!element) return { top: 0 };
      // let top = element.offsetTop - NAVBAR_GAP;
      // return { top };

      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
