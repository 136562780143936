<template>
  <ms-navbar />
  <router-view />
</template>

<script>
import MsNavbar from "@/components/Navbar.vue";
export default {
  name: "App",
  components: { MsNavbar },
};
</script>
